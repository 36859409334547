.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  /* max-width: 1000px; */
  justify-content: center;
  font-family: 'Lato', sans-serif;
  line-height: 1.5em;
  width: 100%;
  /* border: 1px solid green; */
}
.content {
  margin-top: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
}

.bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: white;
  z-index: 999;
  border-bottom: 1px solid gray;
  align-items: center;
}

.recoverBlurb {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* border: 1px solid red; */
  /* min-height: 500px; */
  justify-content: flex-end;
  margin-bottom: 200px;
}

.recoverBlurb > img {
  position: absolute;
  margin-left: -400px;
  left: 0;
  max-width: 800px;
  /* border: 1px solid green; */
}

.recoverBlurb > .recoverBlurbText {
  /* margin-left: 400px; */
  /* height: 100%; */
  max-width: 500px;
  text-align: left;
  z-index: 998;
  /* border: 1px solid red; */
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
}

.bigFtr {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  /* border-bottom: 5px solid gray; */
}

.examples > div {
  margin-left: 20px;
  font-size: 0.9em;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.examples {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
  line-height: 1.5em;
  text-align: left;
  /* padding-left: 220px; */
}

.bigFtr > .img1 {
  max-width: 50%;
}

.img1 > img {
  max-height: 100%;
  max-width: 100%;
  align-self: center;
}

.bigFtr > .imgText {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-top: 30px;
}

.mechanism {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-left: 20px;
  flex-wrap: wrap;
  /* border: 1px solid blue; */
}

.fundingFtr {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  /* width: 100%; */
}

.fundingFtr > .fundingFtrBlurb {
  /* max-width: 300px; */
  text-align: left;
  /* margin-right: 200px; */
  max-width: 500px;
  /* background-color: white; */
  /* z-index: 998; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* border: 1px solid red; */
}

.fundingFtr > .mechanismDiagram {
  /* border: 1px solid red; */
  display: flex;
  max-width: 500px;
}

.fundingFtr > .mechanismDiagram > img {
  /* width: 50%; */
  /* border: 1px solid red; */
  align-self: center;
  object-fit: contain;
  width: 100%;
  /* right: 0; */
  /* position: absolute; */
}

.textInput {
  font-size: 1.5em;
  max-width: 500px;
  /* width: 100%; */
  margin-top: 30px;
  margin-bottom: 30px;
}

.submitTicket {
  max-width: 500px;
  /* width: 100%; */
  /* font-size: 1.5em; */
}

.ToDoSubmit {
  margin-left: 20px;
}

.results {
  padding-bottom: "30px";
  padding-top: "30px"; 
  /* height: 100%; */
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* border: 1px solid red; */
}

.results > .resultsForm {
  /* border-right: 1px solid #D3D3D3; */
  padding-right: 30px;
  height: 100%;
  display: flex;
  /* border: 1px solid red; */
}

hr {
  margin: 50px;
  margin-left: 300px;
  margin-right: 300px;
  color: #f9e1e0;
}

.resultsResp {
  /* border-left: 1px solid #d3d3d3; */
  padding-left: 30px;
}

.logosRow {
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
  width: 80%;
  /* margin-right: 30px; */
}

.tool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  /* height: 80vh; */
  min-height: 1000px;
  /* border: 1px solid red; */
  align-self: center;
}

.resultsRespPre {
  padding: 30px;
  background-color: #f9e1e0;
  border-radius: 15px;
  border: 1px solid #D3D3D3;
  min-height: 10em;
  color: gray;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  line-height: 1.5em;
  min-width: 300px;
}


.ticketsList {
  padding: 30px;
  /* background-color: #f9e1e0; */
  /* border-radius: 15px;
  border: 1px solid #D3D3D3; */
  max-height: 5em;
  overflow-y: scroll;
  /* color: gray; */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  line-height: 1.5em;
}

h1 {
  font-size: 48pt;
  color: #cccccc;
}