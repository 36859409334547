html, body {
  margin: 0;
  font-family: Palatino, Georgia, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  /* border: 1px solid blue; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  width: 100%;
  /* border: 1px solid green; */
}